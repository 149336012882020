var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex justify-center align-center ended" },
    [
      _c(
        "div",
        { staticClass: "d-flex flex-column align-center" },
        [
          _c("AppLogo", { attrs: { width: "150px" } }),
          _c("p", { staticClass: "py-5" }, [
            _vm._v(_vm._s(_vm.$t("broadcastEndedDescription"))),
          ]),
          _c(
            "AppButton",
            { attrs: { color: "secondary" }, on: { click: _vm.handleClick } },
            [_vm._v(_vm._s(_vm.$t(_vm.btnText)))]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }