<template>
  <div class="d-flex justify-center align-center ended">
    <div class="d-flex flex-column align-center">
      <AppLogo width="150px" />
      <p class="py-5">{{ $t('broadcastEndedDescription') }}</p>
      <AppButton @click="handleClick" color="secondary">{{ $t(btnText) }}</AppButton>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import { isAuthenticated } from '@/helpers/keycloak';
import { USER } from '@/constants/modules';

export default {
  name: 'Ended',
  computed: {
    isAuth() {
      return isAuthenticated();
    },
    btnText() {
      return this.isAuth ? 'goToHome' : 'login';
    },
  },
  methods: {
    ...mapActions(USER, ['login']),
    handleClick() {
      if (this.isAuth) {
        this.$router.push('/');
      } else {
        this.login();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ended {
  height: 100%;
}
</style>

